import { FC } from "react";
import { Layout } from "antd";
import "../../styles/MainStyles.scss";

const { Content } = Layout;

interface MainInterface {
  children: any;
}

const Main: FC<MainInterface> = ({ children }) => {
  return (
    <Layout>
      <Content className="container_main">{children}</Content>
    </Layout>
  );
};

export default Main;
