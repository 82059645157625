import { FC } from "react";
import { Layout } from "antd";
import Main from "./Main";
import MenuMain from "./MenuMain";

interface LayoutMainInterface {
  children: any;
}

const LayoutMain: FC<LayoutMainInterface> = ({ children }) => {
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <MenuMain />
      <Main>{children}</Main>
    </Layout>
  );
};

export default LayoutMain;
