// Styles and componets
import "./index.scss";
import App from "./App";
// Utils
import "./i18n";
import { StrictMode } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "./react-auth0-spa";
import auth0Params from "./fixtures/auth0.fixtures";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <StrictMode>
    <Auth0Provider
      domain={auth0Params.initOptions.domain}
      client_id={auth0Params.initOptions.client_id}
      redirect_uri={auth0Params.initOptions.redirect_uri}
      onRedirectCallback={auth0Params.onRedirectCallback}
    >
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Auth0Provider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
