import { useState, useEffect } from "react";

import createAuth0Client, { Auth0Client, User, PopupLoginOptions } from "@auth0/auth0-spa-js";

import i18next from "i18next";
import LanguageOptionsInterface from "../interfaces/languageOptions.interface";

interface InitOptions {
  domain: string;
  client_id: string;
  redirect_uri?: string;
}

type RedirectCallback = (value?: { targetUrl: string }) => void;

export const useAuth0 = (initOptions: InitOptions, onRedirectCallback: RedirectCallback) => {
  const [user, setUser] = useState<User>();
  const [language, setLanguage] = useState("Spanish");
  const [auth0Client, setAuth0] = useState<Auth0Client>();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  const initAuth0 = async () => {
    const auth0FromHook = await createAuth0Client(initOptions);
    setAuth0(auth0FromHook);

    if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
      const { appState } = await auth0FromHook.handleRedirectCallback<{ targetUrl: string }>();
      onRedirectCallback(appState);
    }

    const isAuthenticated = await auth0FromHook.isAuthenticated();

    if (isAuthenticated) {
      const user = await auth0FromHook.getUser();
      setUser(user);
    }

    setLoading(false);
  };

  useEffect(() => {
    initAuth0();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLanguage = async (selectedLanguage: LanguageOptionsInterface) => {
    await i18next.changeLanguage(selectedLanguage.value);
    setLanguage(selectedLanguage.text);
  };

  const loginWithPopup = async (params: PopupLoginOptions | undefined) => {
    setPopupOpen(true);
    try {
      await auth0Client?.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client?.getUser();
    setUser(user);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client?.handleRedirectCallback();
    const user = await auth0Client?.getUser();
    setLoading(false);
    setUser(user);
  };

  return {
    user,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    handleLanguage,
    language,
    auth0Client,
  };
};
