import { GetTokenSilently } from "../interfaces/AuthProvider";
import { AuthContext } from "../react-auth0-spa";

const useToken = () => {
  const { getTokenSilently, getTokenWithPopup } = AuthContext();

  return async ({ audience, scope, detailedResponse = false }: GetTokenSilently) => {
    if (getTokenSilently && getTokenWithPopup) {
      try {
        return await getTokenSilently({
          audience,
          scope,
          detailedResponse,
        });
      } catch (error) {
        try {
          return await getTokenWithPopup({ audience, scope });
        } catch (error) {
          return null;
        }
      }
    }
  };
};

export default useToken;
