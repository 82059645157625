/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from "react";
import type { MenuProps } from "antd";
import { Layout, Menu, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AuthContext } from "../../react-auth0-spa";
import veridocid from "../../assets/veridocid_white.svg";
import { VscArchive, VscAccount, VscInfo, VscPaintcan } from "react-icons/vsc";

const { Sider } = Layout;
const { confirm } = Modal;

type MenuItem = Required<MenuProps>["items"][number];

function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  onClick?: () => void,
  children?: MenuItem[]
): MenuItem {
  return {
    key,
    icon,
    label,
    onClick,
    children,
  } as MenuItem;
}

const MenuMain: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  let text = "hey";

  //Routes
  const customization = () => navigate("/");
  const info = () => {
    if (
      window.sessionStorage.getItem("lvs") ||
      window.sessionStorage.getItem("tempbtnsecondary") ||
      window.sessionStorage.getItem("tempbtnprimary") ||
      window.sessionStorage.getItem("temprcomponent") ||
      window.sessionStorage.getItem("tempcomponent") ||
      window.sessionStorage.getItem("tempwallpp") ||
      window.sessionStorage.getItem("temptitlec") ||
      window.sessionStorage.getItem("temptxtc")
    ) {
      confirm({
        title: "¿Está seguro de salir de esta pantalla?",
        content:
          "Tienes cambios que aún no has guardado, antes de continuar se recomienda guardar sus cambios.",
        cancelText: "Cancelar",
        okText: "Continuar",
        onOk() {
          window.sessionStorage.removeItem("lvs");
          window.sessionStorage.removeItem("tempbtnsecondary");
          window.sessionStorage.removeItem("tempbtnprimary");
          window.sessionStorage.removeItem("temprcomponent");
          window.sessionStorage.removeItem("tempcomponent");
          window.sessionStorage.removeItem("tempwallpp");
          window.sessionStorage.removeItem("temptitlec");
          window.sessionStorage.removeItem("temptxtc");
          navigate("/information");
        },
        onCancel() {
          return null;
        },
      });
    } else {
      navigate("/information");
    }
  };

  // Logout
  const { logout: logoutContext } = AuthContext();
  const logout = logoutContext!;
  const handleLogout = () => {
    if (
      window.sessionStorage.getItem("lvs") ||
      window.sessionStorage.getItem("tempbtnsecondary") ||
      window.sessionStorage.getItem("tempbtnprimary") ||
      window.sessionStorage.getItem("temprcomponent") ||
      window.sessionStorage.getItem("tempcomponent") ||
      window.sessionStorage.getItem("tempwallpp") ||
      window.sessionStorage.getItem("temptitlec") ||
      window.sessionStorage.getItem("temptxtc")
    ) {
      confirm({
        title: "¿Está seguro de salir de esta pantalla?",
        content:
          "Tienes cambios que aún no has guardado, antes de cerrar sesión se recomienda guardar sus cambios.",
        cancelText: "Cancelar",
        okText: "Continuar",
        onOk() {
          window.sessionStorage.removeItem("lvs");
          window.sessionStorage.removeItem("tempbtnsecondary");
          window.sessionStorage.removeItem("tempbtnprimary");
          window.sessionStorage.removeItem("temprcomponent");
          window.sessionStorage.removeItem("tempcomponent");
          window.sessionStorage.removeItem("tempwallpp");
          window.sessionStorage.removeItem("temptitlec");
          window.sessionStorage.removeItem("temptxtc");
          logout({
            returnTo: window.location.origin,
          });
        },
        onCancel() {
          return null;
        },
      });
    } else {
      logout({
        returnTo: window.location.origin,
      });
    }
  };

  return (
    <Sider breakpoint="lg" collapsedWidth="0" style={{ background: "var(--dark-main)" }}>
      <div
        style={{
          color: "white",
          width: "100%",
          height: "40px",
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={veridocid} width="90px" alt="veridocid" />
      </div>
      <Menu
        theme="dark"
        defaultSelectedKeys={["1"]}
        mode="inline"
        items={[
          getItem(`${t("customization")}`, "1", <VscPaintcan />, () => customization()),
          getItem(`${t("info")}`, "2", <VscInfo />, () => info()),
          text === "text" ? getItem("Option 3", "3", <VscArchive />) : null,
          getItem(`${t("account")}`, "sub1", <VscAccount />, () => null, [
            getItem(`${t("logout")}`, "5", null, () => handleLogout()),
          ]),
        ]}
        style={{ background: "inherit" }}
      />
    </Sider>
  );
};

export default MenuMain;
