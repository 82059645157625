import config from "../auth_config.json";
import { AppState } from "../interfaces/AuthProvider";
import history from "../utils/history";

const onRedirectCallback = (appState?: AppState) => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

const auth0Params = {
  initOptions: {
    domain: config.domain,
    client_id: config.clientId,
    redirect_uri: window.location.origin,
  },
  onRedirectCallback,
};

export default auth0Params;
