import { Suspense, lazy, useState, useEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
// Styles and components
import "./index.scss";
import "antd/dist/antd.min.css";
import Loading from "./components/Loading/Loading";
import MenuMain from "./components/Layout";
import { AuthContext } from "./react-auth0-spa";
import useToken from "./hooks/useToken";
import { URL_API } from "./utils/constants";
const Custom = lazy(() => import("./views/Custom"));
const Information = lazy(() => import("./views/Information"));
const NotFound = lazy(() => import("./components/NotFound/NotFound"));

function App() {
  // Auth0
  const { loading, user, loginWithRedirect } = AuthContext();
  const [showPage, setShowPage] = useState<boolean>(false);
  const location = useLocation();
  const getToken = useToken();

  const redirect = async () => {
    if (loginWithRedirect) {
      await loginWithRedirect({
        appState: { targetUrl: location },
      });
    }
  };

  const render = () => {
    if (user) {
      return setShowPage(true);
    }
    return setShowPage(false);
  };

  useEffect(() => {
    if (loading || user) return;
    redirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, loading]);

  useEffect(() => {
    render();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, user]);

  useEffect(() => {
    const getData = async () => {
      const token = await getToken({ audience: "veridocid" });
      const response = await fetch(`${URL_API}/customize-data`, {
        method: "GET",
        headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("btnp", data?.buttonColor ?? "#4D53B9"); //Primary button
        localStorage.setItem("btns", data?.buttonSecondaryColor ?? "#4D53B9"); //Secondary button
        localStorage.setItem("bgrc", data?.componentResponsiveColor ?? "#ECECEC"); //Background responsive component
        localStorage.setItem("bgc", data?.componentColor ?? "#FFFFFF"); //Background component
        localStorage.setItem("wc", data?.wallpaperColor ?? "#D6D6D6"); //Wallpaper
        localStorage.setItem("txtc", data?.textColor ?? "#555555"); //Text color
        localStorage.setItem("txtct", data?.textColorTitle ?? "#26285A"); //Title color
      }
    };

    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  if (loading) return <Loading />;

  return (
    <MenuMain>
      <Suspense fallback={<Loading />}>
        {showPage && (
          <Routes>
            <Route path="/" element={<Custom />} />
            <Route path="/information" element={<Information />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        )}
      </Suspense>
    </MenuMain>
  );
}

export default App;
