import React, { useContext, FC } from "react";
import {
  GetIdTokenClaimsOptions,
  RedirectLoginOptions,
  GetTokenWithPopupOptions,
  LogoutOptions,
} from "@auth0/auth0-spa-js";
import { AuthProviderProps, AuthProvider, GetTokenSilently } from "./interfaces/AuthProvider";

import { useAuth0 } from "./hooks/useAuth0";
import LanguageOptionsInterface from "./interfaces/languageOptions.interface";

const DEFAULT_REDIRECT_CALLBACK = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext<AuthProvider>({
  language: "es",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  handleLanguage: async (_selectedLanguage: LanguageOptionsInterface) => {},
});
export const AuthContext = () => useContext(Auth0Context);

export const Auth0Provider: FC<AuthProviderProps> = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  ...initOptions
}) => {
  const auth = useAuth0(initOptions, onRedirectCallback);
  const {
    user,
    loading,
    popupOpen,
    loginWithPopup,
    handleRedirectCallback,
    auth0Client,
    handleLanguage,
    language,
  } = auth;

  return (
    <Auth0Context.Provider
      value={{
        user,
        loading,
        popupOpen,
        handleLanguage,
        language,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (p: GetIdTokenClaimsOptions) => auth0Client?.getIdTokenClaims(p),
        loginWithRedirect: (p: RedirectLoginOptions) => auth0Client?.loginWithRedirect(p),
        getTokenSilently: (p: GetTokenSilently) => auth0Client?.getTokenSilently(p),
        getTokenWithPopup: (p: GetTokenWithPopupOptions) => auth0Client?.getTokenWithPopup(p),
        logout: (p: LogoutOptions) => auth0Client?.logout(p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
