import { useTranslation } from "react-i18next";
import "./Loading.scss";
const Loading = () => {
  const { t } = useTranslation();
  return (
    <div className="container_loading">
      {/* Loader */}
      <svg
        version="1.1"
        id="L3"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 100 100"
        enableBackground="new 0 0 0 0"
        xmlSpace="preserve"
      >
        <circle
          fill="none"
          stroke="var(--purple-main)"
          strokeWidth="4"
          cx="50"
          cy="50"
          r="44"
          opacity={0.8}
        />
        <circle fill="#fff" stroke="var(--purple-main)" strokeWidth="2" cx="8" cy="54" r="6">
          <animateTransform
            attributeName="transform"
            dur="2s"
            type="rotate"
            from="0 50 48"
            to="360 50 52"
            repeatCount="indefinite"
          />
        </circle>
      </svg>

      <h4>{t("loadEnviroment")}</h4>
    </div>
  );
};

export default Loading;
